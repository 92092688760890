.calculator {
  width: 300px;
  margin: 50px auto;
  border: 1px solid #ccc;
  padding: 20px;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

button {
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

input {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 20px;
  margin-bottom: 10px;
}

/* Additional Button Styles */
button.operator {
  background-color: #ff9c00;
}

button.clear {
  background-color: #ff4f4f;
}

button.calculate {
  background-color: #1dd1a1;
}

button.special {
  background-color: #5f27cd;
}
